@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-tags {
  .merchandise-tags {
    border: 0;
    padding: 0;
    display: block;

    .merchandise-tags-info {
      display: none;
    }
  }

  .merchandise-tag {
    margin-top: 0;
    margin-bottom: 0;

    @include mq('desktop-s', max) {
      margin-right: 6px;
    }

    &__text {
      display: none;
    }

    &--frosty .merchandise-tag__text {
      display: initial;
    }
  }

  &--horizontal {
    padding: 0 0 4px;
  }
}
