@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-card {
  &__image {
    width: 100px;
    height: 75px;
    border-radius: $border-radius-default;
    pointer-events: none;
    align-self: flex-start;
    object-fit: cover;

    @include mq('mobile') {
      width: 120px;
      height: 90px;
    }

    @include mq('tablet') {
      width: 140px;
      height: 140px;
    }

    @include mq('desktop') {
      width: 170px;
      height: 170px;
    }

    &--contained-image {
      object-fit: contain;
    }

    &--red {
      background-color: #f8ddca;
    }

    &--green {
      background-color: #daeebf;
    }

    &--blue {
      background-color: #d2e8f5;
    }
  }

  &__image-placeholder {
    background-image: static-images-url('/webapp/dishes/dish-image-placeholder.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--vertical {
    .dish-card {
      &__image {
        grid-area: image;
        width: 100%;
        height: 100%;
        border-radius: $border-radius-default $border-radius-default 0 0;

        @include mq('tablet') {
          height: 157px;
        }
      }
    }
  }
}
