@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-card-delivery {
  font-size: rem(14);
  line-height: rem(18);
  font-weight: lighter;
  color: $text-color;
  border-top: 2px dashed rgba($text-color-lighten, 0.2);
  margin: 0 20px;

  @include mq('tablet', max) {
    font-size: rem(12);
    line-height: rem(18);
    margin: 0 14px;
  }

  &__time {
    @include mq('tablet', max) {
      display: none;
    }
  }

  &__free-delivery {
    color: $green-color;
  }

  &__top {
    margin: 14px 0;
  }

  &__bottom {
    margin-bottom: 20px;
  }
}
