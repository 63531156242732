@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-promotions {
  background: white;
  padding: 0 5px 15px;
  width: 100%;

  @include mq('desktop-s') {
    padding: 0 0 15px;
  }

  &__dish-card > * {
    width: 225px;

    @include mq('mobile-l') {
      width: 400px;
    }
  }

  .dish-card {
    @include mq('mobile-l') {
      &--vertical {
        height: 380px;
        grid-gap: 23px;
      }

      &__price {
        padding-top: 3px;
      }
    }
  }

  .restaurant-menu-group__title {
    padding: 32px 0 20px $padding-default;

    &::after {
      display: none;
    }

    @include mq('desktop-s') {
      padding-left: 0;
    }
  }
}
