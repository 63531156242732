@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-menu {
  padding-bottom: 40px;

  &__header {
    align-items: center;
    background-color: white;
    z-index: $restaurant-menu-z-index;
    transition: height ease-in-out 200ms;

    &--sticky {
      border-bottom: 1px solid #e6e6e6;

      .restaurant-menu__header-wrapper {
        @include mq('tablet') {
          display: block;
        }

        @include mq('desktop-s') {
          display: flex;
          align-items: center;
        }
      }
    }

    @include mq('desktop-s') {
      display: flex;
      height: 64px;
    }

    &-wrapper {
      width: 100%;

      @include mq('tablet') {
        display: flex;
        padding: 20px $padding-default-desktop 0;
      }

      @include mq('desktop-s') {
        padding-top: 0;
      }

      @include mq('desktop') {
        padding-right: 30px;
        max-width: 1366px;
        margin: 0 auto;
      }
    }

    .marmita-dropdown-menu {
      &__item {
        &--active {
          .btn__label {
            font-weight: bold;
          }
        }
      }

      &__list {
        max-height: calc(3.5 * 56px);

        @include mq('desktop-s') {
          max-height: calc(6.5 * 56px);
        }
      }

      .btn {
        padding: 0 $padding-default;

        @include mq('desktop-s') {
          padding: 16px $padding-default-desktop;
          height: 100%;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }

  &__fast-menu {
    flex: 2;
    display: none;

    &--sticky {
      display: block;
    }

    @include mq('desktop-s') {
      transition: all ease-in-out 200ms;
      max-width: 0;
      display: none;

      &--sticky {
        display: flex;
        max-width: 1000px;
        height: 34px;
      }
    }
  }

  &__menu-search {
    display: flex;
    position: relative;
    flex: 3;
    align-items: center;
    transition: all ease-in-out 200ms, border 0ms;
    padding: 20px 16px 0;
    border-bottom: 0;
    margin-top: 0;

    .icon-marmita--search {
      width: 20px;
      height: 20px;
    }

    @include mq('tablet') {
      border: 1px solid $light-grey-color-100;
      border-radius: 4px;
      display: flex;
      margin-top: 0;
      height: 62px;
      padding: 0;
    }

    &--sticky {
      border: none;
      width: 100%;
      height: 64px;
      padding-top: 0;
    }

    .restaurant-menu-search {
      .icon-marmita.icon-marmita--search svg {
        fill: $primary-color;
        color: $primary-color;
      }

      &__field {
        background-color: $light-grey-color-100;
        border-radius: 4px;
        height: 44px;
        width: 100%;

        @include mq('tablet') {
          background-color: transparent;
        }
      }
    }
  }

  &__scheduling {
    visibility: visible;
    max-width: 1000px;
    margin-left: 16px;
    transition: all ease-in-out 200ms;

    &--sticky {
      visibility: hidden;
      max-width: 0;
      height: 0;
    }
  }

  @include mq('desktop-s', max) {
    &--hidden-search {
      .restaurant-menu {
        &__fast-menu {
          border-bottom: none;
        }

        &__menu-search {
          height: 0;
          opacity: 0;
          pointer-events: none;
          margin-top: 0;
        }
      }
    }
  }

  .dish-card--horizontal {
    @include mq('mobile-l', max) {
      padding: 10px 20px;
    }
  }
}
