@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-card-wrapper {
  position: relative;
  margin-bottom: 14px;

  @include mq('tablet') {
    margin: 0;
    height: 100%;
  }

  .quick-add {
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: flex;
  }
}

.dish-card {
  position: relative;
  display: grid;
  min-height: 147px;
  width: 100%;
  background: #fff;
  padding: 20px;
  text-decoration: none;
  transition: 200ms;
  overflow: hidden;
  height: 100%;

  @media only screen and (max-width: 742px) {
    position: relative;

    @include thin-border-after;
  }

  @media only screen and (min-width: 743px) {
    padding: 15px;
    min-width: 320px;
    border: 1px solid $light-grey-color-100;
    box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
    border-radius: $border-radius-default;

    &:hover {
      border: 1px solid $medium-grey-color-lighten;
    }
  }

  &--has-image {
    grid-template-columns: 1fr 100px;
    grid-gap: 15px;

    @include mq('mobile') {
      grid-template-columns: 1fr 120px;
    }

    @include mq('tablet') {
      grid-template-columns: 1fr 140px;
      grid-gap: 15px;
    }

    @include mq('desktop') {
      grid-template-columns: 1fr 180px;
    }
  }

  .dish-card__container-image {
    position: relative;
  }

  &--sold-out {
    .dish-card__sold-out {
      color: #fff;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgb(0 0 0 / 39%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__info {
    display: grid;
    grid-template-rows: 1fr 20px;
    margin: 0;

    &-top {
      margin-bottom: 23px;

      @include mq('tablet') {
        margin-bottom: 0;
      }
    }
  }

  &__description {
    font-family: $font-family;
    display: grid;
    color: $text-color-super-darken;
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(20);
    margin-top: 0;

    @include mq('tablet') {
      font-size: rem(18);
      line-height: rem(24);
    }
  }

  &__details {
    font-size: rem(12);
    line-height: rem(16);
    font-weight: lighter;
    color: #717171;

    // break-word is deprecated, but it works
    // the intended way for every major browser
    // see: https://developer.mozilla.org/en-US/docs/Web/CSS/word-break
    word-break: break-word;
    margin-bottom: 10px;

    @include mq('mobile-l') {
      font-size: rem(14);
      line-height: rem(20);
    }
  }

  &__tags {
    flex-shrink: 0;
    margin: 6px 0;

    .marmita-minitag {
      white-space: nowrap;
      background-color: transparent;
      font-size: rem(10);

      @include mq('tablet') {
        font-size: rem(12);
      }
    }
  }

  &__closed-overlay {
    position: absolute;
    width: 100%;
    height: 104px;
    background-color: rgb(0 0 0 / 40%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(16);
    color: #fff;

    @include mq('mobile-l') {
      height: 157px;
    }
  }

  &__restaurant-logo {
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 100%;
    left: 6px;
    top: 6px;

    @include mq('mobile-l') {
      width: 50px;
      height: 50px;
      left: 10px;
      top: 10px;
    }
  }

  &--vertical {
    min-width: $dish-column-size;
    height: fit-content;
    grid-template:
      'image' 104px
      'info' 1fr;
    grid-gap: 11px;
    border: 1px solid $light-grey-color-100;
    box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
    border-radius: $border-radius-default;
    padding: 0;

    @include mq('mobile-l') {
      min-width: 222px;
      grid-template:
        'image' 157px
        'info' 1fr;
      grid-gap: 28px;
    }

    &::after {
      display: none;
    }

    .dish-card {
      &__info {
        display: grid;
        grid-area: info;
        grid-template-rows: 1fr;

        &-top {
          margin-bottom: 5px;
          height: 123px;
          padding: 0 12px;

          @include mq('tablet') {
            margin-bottom: 23px;
            padding: 0 20px;
          }

          @include mq('mobile-l', 'max') {
            height: 140px;
          }
        }
      }

      &__description {
        font-size: rem(14);
        line-height: rem(20);
        margin-bottom: 9px;

        @include mq('tablet') {
          font-size: rem(18);
          line-height: rem(24);
          margin-bottom: 9px;
        }
      }

      &__details {
        display: grid;
        font-size: rem(12);
        line-height: rem(16);
        margin-bottom: 0;

        @include mq('mobile-l') {
          font-size: rem(14);
          line-height: rem(20);
          margin-bottom: 10px;
        }
      }
    }
  }
}
