@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.dish-card__price {
  font-size: rem(16);
  line-height: rem(20);
  font-weight: 400;
  color: $input-color;

  &--original {
    position: relative;
    font-size: rem(12);
    line-height: rem(20);
    color: $text-color;
    text-decoration: line-through;
    margin-left: 5px;

    @include mq('desktop') {
      font-size: rem(14);
    }
  }

  &--discount {
    color: $green-color;
  }
}

.dish-card--vertical {
  .dish-card__price {
    padding: 10px 20px;

    @include mq('tablet', max) {
      font-size: rem(14);
      line-height: rem(18);
      padding: 6px 12px;
    }
  }
}
