@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-menu-group {
  position: relative;

  @include thin-border-before;

  @include mq('desktop-s') {
    padding: 0 $padding-default-desktop;
  }

  @include mq('desktop') {
    max-width: 1366px;
    margin: auto;
  }

  @include mq('desktop-wide') {
    padding-right: 30px;
  }

  &::before {
    display: none;
  }

  &__title {
    position: relative;
    font-weight: 500;
    font-size: rem(18);
    padding: 32px $padding-default 20px $padding-default;
    margin: 0;
    color: $black-color;
    width: 100%;

    @include thin-border-after;

    @media only screen and (min-width: 743px) {
      &::after {
        display: none;
      }
    }

    @include mq('desktop-s') {
      font-size: rem(24);
      letter-spacing: -1px;
      padding: 40px 0 20px;
    }
  }

  &__container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, minmax(320px, 1fr));
    grid-gap: 0;
    margin: 0;

    @include mq('mobile-l') {
      grid-template-columns: repeat(1, minmax(420px, 1fr));
    }

    @media only screen and (min-width: 743px) {
      grid-template-columns: repeat(2, minmax(320px, 1fr));
      grid-gap: 30px;
      padding: 0 $padding-default;
    }

    @include mq('desktop-s') {
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
    }
  }

  &__loading {
    position: relative;
    margin-top: 40px;
  }
}
