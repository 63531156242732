@use "sass:color"; @use 'sass:meta'; @use 'sass:map'; @import "styles/core.scss";
.restaurant-container {
  display: block;

  @include mq('desktop-s') {
    display: flex;
    padding: 0;
  }

  .restaurant-column {
    flex-basis: 100%;
    width: 100%;

    @include mq('desktop') {
      padding-bottom: 30px;
      max-width: 100%;
    }
  }

  .order-column {
    display: none;
    height: calc(100vh - 80px);
    position: fixed;
    right: 0;

    @include mq('desktop') {
      display: flex;
      flex-basis: $cart-width;
    }

    box-shadow: -3px 0 10px -5px rgb(0 0 0 / 30%);
    z-index: $fixed-side-cart-z-index;
    background: #fff;

    .restaurant-cart {
      height: calc(100vh - 80px);
    }
  }

  &__error-message {
    margin-top: 70px;
  }

  &__banner--hidden {
    display: none;

    @include mq('desktop-s') {
      display: flex;
    }
  }

  .restaurant-info {
    margin-top: 118px;

    @include mq('tablet') {
      margin-top: 200px;
    }

    @include mq('desktop-s') {
      margin-top: 30px;
    }
  }
}

.voucher-header-restaurant-wrapper {
  position: absolute;

  @include mq('desktop') {
    width: 100%;
  }

  .voucher-header {
    position: relative;
    top: 0;

    &-band {
      width: 100vw;
    }
  }

  ~ .restaurant-container {
    position: relative;
    top: 60px;
  }
}
